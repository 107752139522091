li{
	cursor: pointer;
	padding: 10px;


}

li:hover{
	background:#0275d8;
    opacity: 10;
    border-radius: 10px;
    color:greenyellow;
}

.procedimiento{
	overflow-y:scroll;
	height:350px;
}

.espacio{
	margin-right: 5px;

}


.menuStilos{

	padding-top: 50px;
	background:#fafdff;
}

.buscar_doctor{
		overflow-x: scroll;
		height: 400px;
		


}

.boxslider{
	overflow-y:scroll;
	height:200px;
}

.titulo_kadosh{
	margin-left: 30%;

}

.interfaz_reporte{
	overflow-y:scroll;
	height: 400px;
}

.interfaz_cliente{
	overflow-y:scroll;
	height: 500px;
}
.interfaz_perfil{

}
.tableflow{
	overflow-y: scroll;
	height: 250px;
}
.boton_pos{
	margin-left: 80%;
}
.boton_perfil{
	margin-left:0%;
}
.boton_doctor{
	margin-left: 80%;
}

.boton_paciente{
		margin-left: 84%;
}	

.logo_flow{
	WIDTH: 500PX;
    HEIGHT: 500PX;
    margin-left: 170px;
}
@media (min-width: 320px) and (max-width: 480px) {

	.boton_pos{
		margin-left: 50%;
	}
	.boton_perfil{
		margin-top: 3%;
   	    margin-left: 20%;
	}
	.boton_doctor{
		margin-left: 58%;
	}
	.boton_paciente{
		margin-left: 58%;
	}



}

.stilo_login{
    margin-top: 120px;
    border: solid 2px;
    border-color: #e5e1e1;
    /* box-shadow: 2px 1px 1px 0.5px; */
    background: #f9fafb;
}

body{
	background:#eff1f2;
}

.boton_login{
	width: 100%;
}

.padding_text{
	padding: 2px;
}

.img_estilo{
	height: 40px;
	width: 40px;
}


body{

	background: #F7F7F7;
	
}

.estilo_notas{

	overflow-y: scroll;
	height: 400px;;
}